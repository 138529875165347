import React from "react"
import { Link } from "gatsby"
import { Row, Col, Card } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { FormEventAnalytics } from "../../utils/analytics"
import { setUTMSource } from "../../utils/storage"
import scrollTo from "gatsby-plugin-smoothscroll"

class LMS extends React.Component {
  componentDidMount() {
    setUTMSource()
  }

  render() {
    return (
      <Layout>
        <SEO
          title={"Learning Management System"}
          titleDesc={
            "Jumpstart your digital school experience with a Learning Management System (LMS) that gives students a new and exciting way to learn."
          }
          titleKeywords={
            "fuse classroom, lms, learning management system, online learning, digital school, online school, smart ai, learning experience"
          }
          fbDes={"Jumpstart your digital school experience with a Learning Management System by Fuse Classroom."}
          twitDesc={"Jumpstart your digital school experience with a Learning Management System by Fuse Classroom."}
          seoLink={"platform/learning-management-system/"}
        />

        {/* top section */}
        <section role="top--section" className="bg-light-green overflow-hidden">
          {/* content */}
          <article className="container">
            <Row className="align-items-center py-4">
              <Col md="6" className="pt-md-5 pb-5">
                <div className="text-green">
                  <h1>Learning Management System (LMS)</h1>
                  <div className="h4 mt-4 text-black font-weight-normal">
                    Jumpstart your digital school experience with a Learning Management System (LMS) that gives students
                    a new and exciting way to learn. Continue reading for more information about the types of tools and
                    features included.
                  </div>

                  <div className="main-cta mt-4">
                    <Link
                      to="/request-for-more-info/"
                      className="btn btn-success mr-3 btn-lg font-weight-semibold"
                      // onClick={() => FormEventAnalytics("LMS_Top_Request_Demo")}
                      state={{ page: "LMS" }}
                      id="request--for-more-info__top"
                    >
                      REQUEST FOR MORE INFO
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="fluid-left d-none d-md-block mt-3 mb-3">
                  <img
                    src="/images/platform/lms/lms-main.webp"
                    alt="Learning Management System"
                    width="640"
                    height="auto"
                  />
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* top section end */}

        {/* LMS */}
        <section className="pb-5 d-none d-md-block" id="ai-info">
          <article className="container ai-features">
            <Row>
              <Col sm="12" className="py-4 mb-5 text-center">
                <div className="text-center title">LMS</div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#assignments-grades")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/asssignments-grades.svg"
                          alt="Assignments & Grades"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">Assignments & Grades</Card.Title>
                      <div className="small">Quizzes, assignments, and grades are easy to create and provide.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#files")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/files.svg" alt="Files" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Files</Card.Title>
                      <div className="small">Files are accessible to all in an easily downloadable format.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Calendar")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/calendar.svg"
                          alt="Calendar"
                          className="img-fluid"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">Calendar</Card.Title>
                      <div className="small">
                        Calendars provide access to all upcoming classes, events, projects, and more.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Announcements")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/announcement.svg"
                          alt="Announcements"
                          className="img-fluid"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">Announcements</Card.Title>
                      <div className="small">
                        Announcements keeping you up to date on class schedules, assignments due dates, and exams.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Classroom-Discussions")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/forum.svg" alt="Classroom Discussions" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Classroom Discussions</Card.Title>
                      <div className="small">Easy and efficient communication between students and instructors.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>
            </Row>
          </article>
        </section>
        {/* LMS End */}

        {/* Assignments & Grades*/}
        <section id="assignments-grades" className="py-5">
          <article className="container py-2">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/lms/assignments-grades.webp"
                  alt="Assignments & Grades"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/asssignments-grades.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Assignments & Grades</h2>
                  <div className="h5 text-black font-weight-normal">
                    The LMS platform was designed to help schools create an innovative online learning experience for
                    students and teachers. Powered with smart AI features, the LMS makes it easier than ever to create
                    and provide quizzes, assignments, and grades.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Assignments & Grades end */}

        {/* Files */}
        <section id="files" className="py-5 bg-80 left">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/platform/lms/files.webp"
                  alt="Files"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/files.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Files</h2>
                  <div className="h5 text-black font-weight-normal">
                    Learning material or assignments files can be easily uploaded by instructors and downloaded by
                    students. Fuse Classroom ensures accessibility to students by automatically converting files to
                    their desired format (HTML, PDF, etc).
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Files end */}

        {/* Calendar */}
        <section id="Calendar" className="py-5">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/lms/calendar.webp"
                  alt="Calendar"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/calendar.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Calendar</h2>
                  <div className="h5 text-black font-weight-normal">
                    The calendar feature gives students and instructors access to upcoming classes, events, project
                    assignments, and more. Calendars are automatically updated whenever new events are created.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Calendar end) */}

        {/* Announcements */}
        <section className="py-5 bg-80 left" id="Announcements">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/platform/lms/announcement.webp"
                  alt="Announcements"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/announcement.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Announcements</h2>
                  <div className="h5 text-black font-weight-normal">
                    Students are kept up to date on class schedules, assignments, and exams through the smart
                    announcements feature. Instructors and administrators can easily create and assign announcements.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Announcements end */}

        {/* Classroom Discussions */}
        <section id="Classroom-Discussions" className="py-5">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/lms/forum.webp"
                  alt="Classroom Discussions"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/forum.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Classroom Discussions</h2>
                  <div className="h5 text-black font-weight-normal">
                    Each class has a corresponding discussion portal where students and instructors can communicate. The
                    feature allows students to do things like seek clarification on difficult assignments, share helpful
                    study materials, and more.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Classroom Discussions end) */}

        {/* call to action */}
        <section className="py-5">
          <article className="container">
            <Row className="justify-content-center">
              <Col lg="10">
                <div className="rounded border border-success p-4 text-center">
                  <span className="d-inline-block mr-md-5 mb-0 h4 font-weight-bold">
                    Interested in more information about our platform?
                  </span>
                  {/* <button className="btn btn-success" onClick={() => this.setState({modalShow: true})}>Request a Demo</button> */}
                  <Link
                    to="/request-for-more-info/"
                    className="btn btn-success mt-3 mt-xl-0"
                    // onClick={() => FormEventAnalytics("LMS_Bottom_Request_Demo")}
                    state={{ page: "LMS" }}
                    id="request--for-more-info__bottom"
                  >
                    REQUEST FOR MORE INFO
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
      </Layout>
    )
  }
}

export default LMS
